"use strict";
/* --------------------------------------------------------------
 ThrottleHelper > Static Class
 By Stef Funaro
 www.stefetmarie.com
 @created 23 August 2017, v2 Typescript 27 oct 2022
 -------------------------------------------------------------- */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThrottleHelper = void 0;
class ThrottleHelper {
    /** --------------------------------------------------------------
     PUBLIC STATIC FUNCTIONS
     -------------------------------------------------------------- */
    /**
     * Throttle a function
     * @param fn function
     * @param threshhold integer
     * @param scope
     * @returns {Function}
     */
    static throttle(fn, threshhold = 250, scope = false) {
        let last;
        let deferTimer;
        return function () {
            const context = scope || this;
            const now = +new Date();
            const args = arguments;
            if (last && now < last + threshhold) {
                // hold on to it
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function () {
                    last = now;
                    fn.apply(context, args);
                }, threshhold);
            }
            else {
                last = now;
                fn.apply(context, args);
            }
        };
    }
}
exports.ThrottleHelper = ThrottleHelper;
