"use strict";
/**
 * Gradient animation
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GradientsColors = void 0;
const gsap_1 = require("gsap");
class GradientsColors {
    constructor(el, options) {
        this.animPos = 0;
        this.lastPrc = 50;
        this.currentPrc = 50;
        this.tween = null;
        this.fullWidth = false;
        const instance = this;
        this.canvas = el;
        this.context = this.canvas.getContext('2d');
        this.fullWidth = options["fullwidth"];
        // console.log("constructor this.fullWidth",this.fullWidth);
        this.initColor1 = { r: 11, g: 157, b: 154 };
        this.initColor2 = { r: 181, g: 34, b: 86 };
        this.color1 = this.initColor1;
        this.color2 = this.initColor2;
        this.color1Str = `rgb(${this.color1.r}, ${this.color1.g}, ${this.color1.b})`;
        this.color2Str = `rgb(${this.color2.r}, ${this.color2.g}, ${this.color2.b})`;
        if (options["image"]) {
            this.imageNode = new Image();
            this.imageNode.onerror = function () {
                throw new Error('Granim: The image source is invalid.');
            };
            this.imageNode.onload = function () {
                instance.isImgLoaded = true;
                console.log("onload!");
                instance.update();
            };
            this.imageNode.src = options["image"];
        }
        console.log("constructor");
        this.update();
        // this.interval = setInterval(function(){
        //    // instance.animate();
        // },100);
        addEventListener("scroll", (event) => {
            console.log("scroll");
            instance.update();
        });
        addEventListener("resize", (event) => {
            console.log("resize");
            instance.update();
        });
        // addEventListener("mousemove", (event) => {
        //     var mouseX = event.clientX;
        //     var mouseY = event.clientY;
        //
        //     var element = this.canvas;
        //     var elementRect = element.getBoundingClientRect();
        //     var elementX = elementRect.left;
        //     var elementY = elementRect.top;
        //     var elementWidth = elementRect.width;
        //     var elementHeight = elementRect.height;
        //
        //     var relativeX = ((mouseX - elementX) / elementWidth) * 100;
        //     var relativeY = ((mouseY - elementY) / elementHeight) * 100;
        //
        //     instance.animateToPrc(relativeX);
        // });
        addEventListener("mousemove", (event) => {
            var mouseX = event.clientX;
            // var mouseY = event.clientY;
            var viewportWidth = window.innerWidth;
            // var viewportHeight = window.innerHeight;
            var mouseXPercentage = (mouseX / viewportWidth) * 100;
            // var mouseYPercentage = (mouseY / viewportHeight) * 100;
            // console.log('Mouse X Percentage:', mouseXPercentage);
            // console.log('Mouse Y Percentage:', mouseYPercentage);
            // console.log("move");
            instance.animateToPrc(mouseXPercentage);
        });
    }
    update() {
        this.canvas.style.width = '100%';
        this.canvas.style.height = '100%';
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // if (false) {
        if (this.imageNode) {
            this.context.imageSmoothingEnabled = true;
            // this.context.drawImage(this.imageNode,0,0,(this.canvas.width),(this.canvas.height));
            this.drawImageProp(this.context, this.imageNode);
            // Apply the multiply blending mode
            this.context.globalCompositeOperation = 'multiply';
        }
        const rect = this.canvas.getBoundingClientRect();
        const mouseX = rect.left - this.canvas.width / 2;
        const mouseY = rect.top - this.canvas.height / 2;
        // Calculate the angle of rotation
        const rotationAngle = (this.fullWidth) ? 1 : Math.atan2(mouseY, mouseX);
        // Create the gradient with rotation
        const gradient = this.context.createLinearGradient(0, 0, Math.cos(rotationAngle) * this.canvas.width, Math.sin(rotationAngle) * this.canvas.height);
        // console.log("update this.canvas.width", this.canvas.width);
        // var gradient = this.context.createLinearGradient(0, 0, this.canvas.width, this.canvas.height);
        // console.log("update addColor", this.color1Str, this.color2Str);
        gradient.addColorStop(0, this.color1Str);
        gradient.addColorStop(1, this.color2Str);
        // Apply the gradient color
        this.context.fillStyle = gradient;
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }
    animateToPrc(prc) {
        // console.log("animateToPrc!",prc);
        if (prc > 100) {
            prc = 100;
        }
        if (prc < 0) {
            prc = 0;
        }
        this.animateTransition(this.lastPrc, prc);
    }
    animateTransition(fromNumber, toNumber) {
        // console.log("animateTransition", fromNumber, toNumber);
        this.currentPrc = fromNumber;
        if (this.tween !== null) {
            this.tween.kill();
        }
        // console.log("animateTransition");
        this.tween = gsap_1.default.to(this, 2, { "currentPrc": toNumber, onUpdate: this.doAnim, callbackScope: this });
    }
    doAnim() {
        // console.log("doAnim", this.currentPrc);
        this.applyTransitionPrc(this.currentPrc);
    }
    applyTransitionPrc(prc) {
        this.lastPrc = prc;
        // console.log("applyTransitionPrc",prc);
        this.color1Str = this.interpolateColors(this.initColor1, this.initColor2, (prc / 100));
        this.color2Str = this.interpolateColors(this.initColor2, this.initColor1, (prc / 100));
        // console.log("applyTransitionPrc", prc);
        this.update();
    }
    drawImageParall() {
    }
    /**
     * Draw image (cover)
     * @param ctx
     * @param img
     * @param x
     * @param y
     * @param w
     * @param h
     * @param offsetX
     * @param offsetY
     */
    drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
        if (arguments.length === 2) {
            x = y = 0;
            w = ctx.canvas.width;
            h = ctx.canvas.height;
        }
        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5;
        offsetY = this.getElementPosition(this.canvas) / 100;
        // keep bounds [0.0, 1.0]
        if (offsetX < 0)
            offsetX = 0;
        if (offsetY < 0)
            offsetY = 0;
        if (offsetX > 1)
            offsetX = 1;
        if (offsetY > 1)
            offsetY = 1;
        var iw = img.width, ih = img.height, r = Math.min(w / iw, h / ih), nw = iw * r, // new prop. width
        nh = ih * r, // new prop. height
        cx, cy, cw, ch, ar = 1;
        // decide which gap to fill
        if (nw < w)
            ar = w / nw;
        if (Math.abs(ar - 1) < 1e-14 && nh < h)
            ar = h / nh; // updated
        nw *= ar;
        nh *= ar;
        // calc source rectangle
        cw = iw / (nw / w);
        ch = ih / (nh / h);
        cx = (iw - cw) * offsetX;
        cy = (ih - ch) * offsetY;
        // make sure source rectangle is valid
        if (cx < 0)
            cx = 0;
        if (cy < 0)
            cy = 0;
        if (cw > iw)
            cw = iw;
        if (ch > ih)
            ch = ih;
        // fill image in dest. rectangle
        ctx.drawImage(img, Math.round(cx), Math.round(cy), Math.round(cw), Math.round(ch), Math.round(x), Math.round(y), Math.round(w), Math.round(h));
    }
    interpolateColors(startColor, endColor, progress) {
        const r = Math.round(startColor.r + (endColor.r - startColor.r) * progress);
        const g = Math.round(startColor.g + (endColor.g - startColor.g) * progress);
        const b = Math.round(startColor.b + (endColor.b - startColor.b) * progress);
        return `rgb(${r}, ${g}, ${b})`;
    }
    getElementPosition(element) {
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const positionY = (rect.top / viewportHeight) * 100;
        return positionY;
    }
}
exports.GradientsColors = GradientsColors;
