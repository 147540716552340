"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.System = void 0;
/**
 * System class
 * Mettre ici des infos sur le système, des getters, faites les manipulations ailleurs
 *
 * @author Stef Funaro
 *
 */
const Configs_1 = require("./Configs");
class System {
    static get isHttps() {
        return (document.location.protocol === "https:");
    }
    static get isMobile() {
        const query = window.matchMedia(`only screen and (max-width: ${Configs_1.default.mobileScreenSize}px)`);
        return query.matches;
    }
    /**
     * get all css variables
     */
    static get cssVariables() {
        if (this._cssVariables == null) {
            this._cssVariables = window.getComputedStyle(document.body);
        }
        return this._cssVariables;
    }
    /**
     * get css variable by name
     * @param name
     */
    static getCssVariable(name) {
        return this.cssVariables.getPropertyValue(`--${name}`);
    }
    /**
     * Get hash value in URL
     * return string | boolean
     */
    static get urlHash() {
        let value = false;
        const hash = location.hash;
        if (hash) {
            value = location.hash.substr(1);
        }
        return value;
    }
    /**
     * Get has Hash Value in URL
     * @return {bool}
     */
    static get urlHasHash() {
        const hash = location.hash;
        return !!hash;
    }
}
exports.System = System;
