"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * IMPORT LIBRARIES
 */
require("bootstrap");
require("swiper/bundle");
/**
 * IMPORT APP
 */
const App_1 = require("./dx/App");
/**
 * APP INIT
 */
// Instance app
const app = new App_1.App();
// On doc ready
document.addEventListener("DOMContentLoaded", function (event) {
    setTimeout(function () {
        // Petite sécurité qui nous a réglé plusieurs problèmes random, doc ready + timeout de zero avant de starter.
        app.init();
    }, 0);
});
