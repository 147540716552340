"use strict";
/**
 * TomSelects class
 * Ici on gère les select "tom select"
 *
 * Documentation:
 * https://tom-select.js.org/
 *
 *
 * @author Stef Funaro
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TomSelects = void 0;
class TomSelects {
    constructor() {
        console.log("TomSelects.constructor");
    }
    init() {
        // var settings = {};
        // new TomSelect('#tom-select-it',settings);
        // var tommult = new TomSelect("#select-exemple",{
        // 	maxItems: 3
        // });
        console.log("TomSelects.init");
    }
}
exports.TomSelects = TomSelects;
