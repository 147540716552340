"use strict";
/**
 * Gradient animation
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BtnScrollColor = void 0;
class BtnScrollColor {
    constructor(el) {
        const instance = this;
        const button = el;
        window.addEventListener("scroll", (event) => {
            var scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100;
            var color1 = '#0b9d9a';
            var color2 = '#f9035a';
            var currentColor = instance.interpolateColor(color1, color2, scrollPercentage);
            button.style.backgroundColor = currentColor;
        });
    }
    interpolateColor(color1, color2, percentage) {
        var color1Rgb = this.hexToRgb(color1);
        var color2Rgb = this.hexToRgb(color2);
        if (color1Rgb == null || color2Rgb == null) {
            return "null";
        }
        var r = Math.round(color1Rgb.r + (color2Rgb.r - color1Rgb.r) * (percentage / 100));
        var g = Math.round(color1Rgb.g + (color2Rgb.g - color1Rgb.g) * (percentage / 100));
        var b = Math.round(color1Rgb.b + (color2Rgb.b - color1Rgb.b) * (percentage / 100));
        return 'rgb(' + r + ',' + g + ',' + b + ')';
    }
    // Function to convert hexadecimal color to RGB
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
}
exports.BtnScrollColor = BtnScrollColor;
