"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Layout = void 0;
const ThrottleHelper_1 = require("../helpers/ThrottleHelper");
const System_1 = require("../core/System");
const js_match_height_1 = require("js-match-height");
class Layout extends EventTarget {
    constructor(app) {
        super();
        this.app = app;
        // Leave at off if you don't use it for better performance
        this._checkBreakpointChangeOnResize = true;
        this._lastBreakpoint = null;
        this._currentBreakpoint = null;
        this.ON_DOC_RESIZE_EVENT = "layout-resize-event";
        this.ON_BREAKPOINT_CHANGE_EVENT = "layout-breakpoint-change-event";
        console.log("Henri Layout constructor");
    }
    init() {
        console.log("Henri Layout init!!", this.app.animation);
        this._initBreakpoints();
        // Resize event (slowed down)
        window.addEventListener("resize", ThrottleHelper_1.ThrottleHelper.throttle(this._handleDocResize, 1000, this));
        window.dispatchEvent(new Event("resize"));
        // on orientation change event
        window.addEventListener("orientationchange", this._handleDocResize.bind(this));
        const matchHeight = new js_match_height_1.MatchHeight('.matchHeight');
        this._doSpecialTricks();
        return this;
    }
    /**
     * @returns {Number}
     */
    get winW() {
        return this._winW;
    }
    /**
     * @returns {Number}
     */
    get winH() {
        return this._winH;
    }
    /**
     * @returns {Number}
     */
    get viewportW() {
        return this._viewportW;
    }
    /**
     * @returns {Number}
     */
    get viewportH() {
        return this._viewportH;
    }
    /**
     * Is the viewport smaller than breakpoint ?
     * @param breakpoint {string}
     * @returns {boolean}
     */
    isUnderBreakpoint(breakpoint) {
        return (this._viewportW < this[`bp_${breakpoint}`]);
    }
    /**
     * is Breakpoint bigger or equal than breakpoint ?
     * @param breakpoint {string}
     * @returns {boolean}
     */
    isOverBreakpoint(breakpoint) {
        return (this._viewportW >= this[`bp_${breakpoint}`]);
    }
    /***********************************************************
     * PRIVATES
     ************************************************************/
    /**
     * @private
     */
    _doSpecialTricks() {
        // Automatic centering Vertical - Only once on load
        document.querySelectorAll(".js-center-v-once").forEach((element, index) => {
            this._centerV(element);
        });
        // Automatic centering Horizontal - Only once on load
        document.querySelectorAll(".js-center-h-once").forEach((element, index) => {
            this._centerH(element);
        });
        this._hackVisualComposer();
    }
    /**
     * Hack de Visual Composer
     * @private
     */
    _hackVisualComposer() {
        // TODO: Test hack VC
        // $(".vc_row").wrapInner("<div class='vc_row_inner'></div>");
        const items = document.querySelectorAll("div.vc_row");
        for (const item of items) {
            const wrapper = document.createElement("div");
            wrapper.className = "vc_row_inner";
            for (const itemContent of item.childNodes) {
                wrapper.appendChild(itemContent);
            }
            item.appendChild(wrapper);
        }
    }
    /**
     * Centrage vertical dans son parent (dernier recours, essayez fort en css avant!)
     * @private
     */
    _centerV(element) {
        if (element.style.position === "static") {
            element.style.position = "relative";
        }
        // TODO: convert jquery center
        // let t = Math.floor($(this).parent().height() / 2) - $(this).height() / 2;
        // if (t < 0) {
        // 	t = 0;
        // }
        // element.style.top = t + "px";
    }
    /**
     * Centrage horizontal dans son parent (dernier recours, essayez fort en css avant!)
     * @private
     */
    _centerH(element) {
        if (element.style.position === "static") {
            element.style.position = "relative";
        }
        // TODO: convert jquery center
        // let l = Math.floor($(this).parent().width() / 2) - $(this).width() / 2;
        // if (l < 0) {
        // 	l = 0;
        // }
        // element.style.left = l + "px";
    }
    /**
     * Resize
     */
    _handleDocResize() {
        this._winW = window.innerWidth;
        this._winH = window.innerHeight;
        this._viewportW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this._viewportH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        // Check breakpoint change
        if (this._checkBreakpointChangeOnResize) {
            this._lastBreakpoint = this._currentBreakpoint;
            // for (let i = 0; i < this._breakpointsNamesArray.length; i++) {
            for (let i = this._breakpointsNamesArray.length - 1; i >= 0; i--) {
                // console.log("_handleDocResize check bp", this._viewportW, this._breakpointsNamesArray[i], this[`bp_${this._breakpointsNamesArray[i]}`]);
                if (this._viewportW < this[`bp_${this._breakpointsNamesArray[i]}`]) {
                    this._currentBreakpoint = this._breakpointsNamesArray[i];
                }
                else {
                    break;
                }
            }
            if (this._currentBreakpoint !== this._lastBreakpoint) {
                this._onBreakpointChange();
            }
        }
        // Automatic centering Vertical
        document.querySelectorAll(".js-center-v").forEach((element, index) => {
            this._centerV(element);
        });
        // Automatic centering Horizontal
        document.querySelectorAll(".js-center-h").forEach((element, index) => {
            this._centerH(element);
        });
        const event = new CustomEvent(this.ON_DOC_RESIZE_EVENT, { detail: { w: this._winW, h: this._winH, breakpoint: this._currentBreakpoint } });
        console.log("Layout._handleDocResize", event);
        this.dispatchEvent(event);
        // this.dispatchEvent(this.ON_DOC_RESIZE_EVENT, this._winW, this._winH);
        return true;
    }
    /**
     * Stockage de breakpoints du site
     * Pris des variables dans le root du css (déclarées dans constants.scss)
     * @private
     */
    _initBreakpoints() {
        // Breakpoints list
        const breakpointsFromCss = System_1.System.getCssVariable("breakpoints-names");
        this._breakpointsNamesArray = breakpointsFromCss.split(",");
        console.log("Layout._breakpointsNamesArray=", this._breakpointsNamesArray);
        let bp;
        for (let i = 0; i < this._breakpointsNamesArray.length; i++) {
            bp = parseInt(System_1.System.getCssVariable(`breakpoint-${this._breakpointsNamesArray[i]}`), 10);
            this["bp_" + this._breakpointsNamesArray[i]] = (isNaN(bp)) ? 0 : bp;
            console.log("Layout.breakpoint", this._breakpointsNamesArray[i], this[`bp_${this._breakpointsNamesArray[i]}`]);
        }
        return true;
    }
    /**
     * Changement de breakpoint
     * @private
     */
    _onBreakpointChange() {
        const event = new CustomEvent(this.ON_BREAKPOINT_CHANGE_EVENT, { detail: { w: this._winW, h: this._winH, breakpoint: this._currentBreakpoint, lastBreakpoint: this._lastBreakpoint } });
        this.dispatchEvent(event);
        console.log("Layout._onBreakpointChange", event);
        return true;
    }
}
exports.Layout = Layout;
