"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Animation = void 0;
const AOS = require("aos");
const Rellax = require("rellax");
// import * as Granim from "granim";
const GradientsColors_1 = require("../animations/GradientsColors");
const GradientsBgBtn_1 = require("../animations/GradientsBgBtn");
const BtnScrollColor_1 = require("../animations/BtnScrollColor");
class Animation {
    constructor(app) {
        this.app = app;
        this._doCustomAnimations();
    }
    init() {
        this._doCustomAnimations();
        this._setRellax();
        this._setAOS();
        this._setGranim();
        // this._setVivus();
        // this._setMarquee();
        // On Scroll
        // document.addEventListener("scroll", ThrottleHelper.throttle(this._handleScroll, 100, this));
        return this;
    }
    /***************************************************
     * Privates
     **************************************************/
    /**
     * Custom animations
     */
    _doCustomAnimations() {
        // gsap.to(".module-hero", 2, {opacity:1});
    }
    /**
     * _handleScroll animations
     * @private
     */
    _handleScroll() {
    }
    _setGranim() {
        document.querySelectorAll(".granim-photo").forEach((granimEl, index) => {
            var gradientAnim = new GradientsColors_1.GradientsColors(granimEl, { "image": granimEl.dataset.src, "fullwidth": granimEl.classList.contains("full-width") });
        });
        document.querySelectorAll(".granim-btn").forEach((granimEl, index) => {
            var gradientAnim = new GradientsBgBtn_1.GradientsBgBtn(granimEl);
        });
        document.querySelectorAll(".scrollcolor-btn").forEach((scrollColorEl, index) => {
            var scrollColorAnim = new BtnScrollColor_1.BtnScrollColor(scrollColorEl);
        });
        return;
    }
    /**
     * Set Rellax
     * Animation parallax en scroll (éléments flottants)
     * https://dixonandmoe.com/rellax/
     *
     * Attention:
     * Faire npm install rellax --save
     * Après: import dans ce fichier
     *
     */
    _setRellax() {
        const rellax = new Rellax(".rellax", {
            speed: -2,
            center: true,
            //wrapper: ".section-point-a-z",
            // round: true,
            // vertical: true,
            // horizontal: false
        });
    }
    /**
     * _setVivus
     * @private
     *
     * Animation de svg. Effect cool de svg qui se dessine, ne fonctionne que sur les "strokes"
     * ATTENTION: Il faut installer la librairie: npm install vivus
     * Et l'importer dans ce fichier
     *
     * https://maxwellito.github.io/vivus/
     * Preview svg: https://maxwellito.github.io/vivus-instant/
     *
     */
    _setVivus() {
        // Loop et ajout automatique d'ID pour animer les svg
        // let vivCnt = 0;
        // $(".animated-svg").each(function () {
        // 	$(this).attr("id", "viv-" + vivCnt);
        // 	new Vivus("viv-" + vivCnt, { duration: 120, delay: 100 });
        // 	vivCnt++;
        // });
    }
    /**
     * _setMarquee
     * @private
     *
     * Bande défilante (vanilla)
     * https://www.npmjs.com/package/vanilla-marquee
     *
     * ATTENTION: installer avec npm i vanilla-marquee
     * Et importer: import marquee from 'vanilla-marquee'
     *
     */
    _setMarquee() {
        // new marquee( element, {
        // 	options
        // })
    }
    /**
     * _setAOS
     * @private
     *
     * Animation en scroll
     *
     */
    _setAOS() {
        // AOS
        AOS.init({
            // Global settings:
            // disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            // initClassName: 'aos-init', // class applied after initialization
            // animatedClassName: 'aos-animate', // class applied on animation
            // useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            // disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
            // // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            // offset: 120, // offset (in px) from the original trigger point
            // delay: 0, // values from 0 to 3000, with step 50ms
            duration: 1800, // values from 0 to 3000, with step 50ms
            // easing: 'ease', // default easing for AOS animations
            once: true, // whether animation should happen only once - while scrolling down
            mirror: false // whether elements should animate out while scrolling past them
            // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }
}
exports.Animation = Animation;
