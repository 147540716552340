"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navigation = void 0;
const System_1 = require("../core/System");
const ThrottleHelper_1 = require("../helpers/ThrottleHelper");
const gsap_1 = require("gsap");
const bootstrap = require("bootstrap");
class Navigation {
    constructor(app) {
        this.app = app;
        //readonly _scrollToOffset: number = 300;
        this._scrollToOffset = 90;
        this._lastScroll = 0;
        // Sticky
        this._stickyNav = false;
        // À quelle position de scroll, le style de la nav change
        this._stickyNavStyleSwitchedPosition = 50;
        // À quelle position de scroll, la nav sticky se cache
        this._stickyNavShowHidePosition = 400;
        this._stickySubHeroNavShowHidePosition = 800;
        console.log("Henri Navigation constructor");
        this._headerEl = this.app.mainContainer.querySelector("header");
        if (this._headerEl === null) {
            console.error("Navigation missing <header>");
            return;
        }
        this._mainNav = this._headerEl.querySelector("#navbar-main");
        if (this._mainNav === null) {
            console.error("Navigation missing div: #navbar-main");
            return;
        }
        // Sticky nav
        const stickyNavEl = this._headerEl.querySelector(".sticky-nav");
        if (stickyNavEl !== null) {
            this._stickyNav = true;
            this._stickyNavEl = stickyNavEl;
        }
        // History nav
        const historyNavEl = document.querySelector("#histo-menu-box");
        if (historyNavEl !== null) {
            this._historyNavEl = historyNavEl;
            this._setHistoryNav();
        }
        // Size ou la nav se collapse
        this._navCollapseBreakpointSize = parseInt(System_1.System.getCssVariable("navbar-collapse-size"), 10);
    }
    /******************************************
     * Public
     * ******************************************/
    init() {
        console.log("Henri Navigation init!");
        this._setAnchorLinks();
        // Resize event (slowed down)
        document.addEventListener("scroll", ThrottleHelper_1.ThrottleHelper.throttle(this._handleScroll, 100, this));
        document.dispatchEvent(new Event("scroll"));
        // S'il y a un hash, on y scroll en anim!
        if (System_1.System.urlHasHash) {
            const hash = System_1.System.urlHash;
            if (typeof hash === "string") {
                this.scrollToElemId(hash);
            }
        }
        // Burger
        this._setBurgerNav();
        //Mobile tabs
        const instance = this;
        const ongletsItems = this._mainNav.querySelectorAll("a.onglet-item");
        Array.from(ongletsItems).forEach(ongletsItem => {
            ongletsItem.addEventListener("click", function (e) {
                var _a, _b, _c;
                e.preventDefault();
                const clickEl = e.currentTarget;
                (_a = instance._mainNav.querySelector(".onglet-item.active")) === null || _a === void 0 ? void 0 : _a.classList.remove("active");
                (_b = instance._mainNav.querySelector(".onglet-active")) === null || _b === void 0 ? void 0 : _b.classList.remove("onglet-active");
                clickEl.classList.add("active");
                const targetID = clickEl.dataset.target;
                if (targetID !== undefined) {
                    (_c = instance._mainNav.querySelector(targetID)) === null || _c === void 0 ? void 0 : _c.classList.add("onglet-active");
                }
            });
        });
        return this;
    }
    /**
     * Scroll to element with #ID
     * @param id
     */
    scrollToElemId(id) {
        // On enleve le # pour etre sur de partir de la meme chose, qu'il y ait un # ou non
        const cleanedId = id.replace("#", "");
        const elem = document.querySelector(`#${cleanedId}`);
        if (elem !== null) {
            this.scrollToElem(elem);
        }
    }
    scrollToElem(elem) {
        console.log("scrollToElem", elem);
        const rect = elem.getBoundingClientRect();
        const targetOffset = (rect.top + window.scrollY) - this._scrollToOffset;
        this.scrollToPos(targetOffset);
    }
    scrollToPos(pos) {
        // gsap.to(window, this._scrollToSpeed, { scrollTo: pos });
        window.scrollTo({
            top: pos,
            behavior: "smooth"
        });
        console.log("scrollToPos", pos);
    }
    /******************************************
     * BURGER
     * ******************************************/
    /**
     * Burger Nav
     * @private
     */
    _setBurgerNav() {
        // Burger btn exists ?
        const burgerBtn = this._headerEl.querySelector("button[data-toggle='collapse-henri']");
        if (burgerBtn === null) {
            return;
        }
        // Yes, keep going!
        this._burgerBtn = burgerBtn;
        // Collapsed nav exists ?
        const collapseNavElId = this._burgerBtn.dataset.target;
        const collapseNavEl = this._headerEl.querySelector(collapseNavElId);
        if (collapseNavEl === null) {
            return;
        }
        // Yes, keep going!
        // GO!
        this._mainNavIsCollapsable = true;
        this._collapseNavEl = collapseNavEl;
        // Button click
        this._burgerBtn.addEventListener("click", (e) => {
            e.preventDefault();
            const target = e.currentTarget;
            if (target.classList.contains("collapsed")) {
                // Is closed !
                target.classList.remove("collapsed");
                this.openBurgerNav();
            }
            else {
                // Is opened ?
                target.classList.add("collapsed");
                this.closeBurgerNav();
            }
        });
    }
    /**
     * Ouverture du menu burger
     */
    openBurgerNav() {
        document.body.classList.add("opened-nav");
        this.app.mainContainer.classList.add("opened-nav");
        gsap_1.default.fromTo(this._collapseNavEl, 0.4, { opacity: 0 }, { opacity: 1, display: "block" });
    }
    /**
     * Fermerture du menu burger
     */
    closeBurgerNav() {
        document.body.classList.remove("opened-nav");
        this.app.mainContainer.classList.remove("opened-nav");
        gsap_1.default.to(this._collapseNavEl, 0.4, {
            opacity: 0,
            display: "none",
            onComplete: (e) => {
                // Enlever l'opacity, parce qu'en resize, le display change par css avec !important et on veut pas de l'opacité dans les pattes
                this._collapseNavEl.style.opacity = "1";
            }
        });
    }
    /******************************************
     * Privates
     * ******************************************/
    /**
     * on scroll event
     * @private
     */
    _handleScroll(event) {
        const scrollTop = window.scrollY;
        //console.log("_handleScroll", scrollTop, this._lastScroll);
        // console.log("_onScroll scrollTop=", scrollTop);
        if (this._stickyNav) {
            // Petit truc pour faire disparaitre nav en scroll et réaparaitre en scroll up
            // console.log("_onScroll this.stickyNavBuffSize=", this.stickyNavBuffSize);
            if (scrollTop > this._stickySubHeroNavShowHidePosition) {
                this.app.mainContainer.classList.add("nav-scrolled-second-sub-show");
            }
            else {
                this.app.mainContainer.classList.remove("nav-scrolled-second-sub-show");
            }
            if (scrollTop < this._stickyNavShowHidePosition) {
                document.body.classList.remove("nav-scrolled-hide");
                this.app.mainContainer.classList.remove("nav-scrolled-hide");
            }
            else {
                if (this._lastScroll <= scrollTop) {
                    document.body.classList.add("nav-scrolled-hide");
                    this.app.mainContainer.classList.add("nav-scrolled-hide");
                    //Cette classe là ne s'enlève jamais.
                    this.app.mainContainer.classList.add("nav-scrolled-hide-point-passed");
                }
                else {
                    document.body.classList.remove("nav-scrolled-hide");
                    this.app.mainContainer.classList.remove("nav-scrolled-hide");
                }
            }
            this._lastScroll = scrollTop;
            // Petit truc qui change le look de la nav quand on est un peu scrollé dans la page
            if (scrollTop > this._stickyNavStyleSwitchedPosition) {
                document.body.classList.add("nav-scrolled");
                this.app.mainContainer.classList.add("nav-scrolled");
            }
            else {
                document.body.classList.remove("nav-scrolled");
                this.app.mainContainer.classList.remove("nav-scrolled");
            }
        }
    }
    /**
     * Lien avec ancres, animation
     * @private
     */
    _setAnchorLinks() {
        const anchorLinks = this.app.mainContainer.querySelectorAll("a[href^='#']:not([href='#'])");
        anchorLinks.forEach((link, index) => {
            // Exceptions
            if (link.hasAttribute("data-vc-accordion"))
                return;
            if (link.hasAttribute("data-vc-tabs"))
                return;
            if (link.hasAttribute("data-toggle"))
                return;
            // Click
            link.addEventListener("click", (e) => {
                const target = e.target;
                if (!target)
                    return;
                e.preventDefault();
                const sectionID = target.getAttribute("href");
                if (sectionID !== null) {
                    const elem = document.querySelector(sectionID);
                    if (elem !== null) {
                        return this.scrollToElem(elem);
                    }
                }
            });
        });
        //Accordéons
        const accordLinks = this.app.mainContainer.querySelectorAll(".accordion-button");
        accordLinks.forEach((link, index) => {
            // Exceptions
            // Click
            link.addEventListener("click", (e) => {
                const target = e.target;
                if (!target)
                    return;
                //e.preventDefault();
                console.log("target", target);
                setTimeout(() => {
                    return this.scrollToElem(target);
                }, 300);
            });
        });
    }
    _setHistoryNav() {
        const instance = this;
        const historyScrollSpyEl = document.querySelector("#historique-scrollspy");
        if (historyScrollSpyEl == null) {
            return;
        }
        var scrollSpy = bootstrap.ScrollSpy.getInstance(historyScrollSpyEl);
        const activeBtn = instance._historyNavEl.querySelector(".menu-active-txt-btn");
        historyScrollSpyEl.addEventListener('activate.bs.scrollspy', function (e) {
            activeBtn.textContent = e.relatedTarget.textContent;
        });
        activeBtn.addEventListener("click", function (e) {
            e.preventDefault();
            e.target.classList.toggle("open");
            historyScrollSpyEl.querySelector(".histo-menu-in").classList.toggle("open");
        });
    }
}
exports.Navigation = Navigation;
