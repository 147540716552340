"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Forms = void 0;
class Forms {
    constructor(app) {
        this.app = app;
        console.log("Henri Forms constructor");
    }
    init() {
        console.log("Henri Forms init!");
        return this;
    }
}
exports.Forms = Forms;
