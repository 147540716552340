"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
/**
 * App class
 * Classe principale.
 *
 * @author Stef Funaro
 *
 */
const Layout_1 = require("./ui-controllers/Layout");
const Animation_1 = require("./ui-controllers/Animation");
const Forms_1 = require("./ui-controllers/Forms");
const Navigation_1 = require("./ui-controllers/Navigation");
const Swipers_1 = require("./components/Swipers");
const SocialShareLinks_1 = require("./components/SocialShareLinks");
const Slide_1 = require("./animations/Slide");
const Lightboxes_1 = require("./components/Lightboxes");
const TomSelects_1 = require("./components/TomSelects");
class App {
    constructor() {
        console.log("Henri App Constructor");
        // Main container
        const mainContainerCheck = document.querySelector("div#dx-page-body");
        if (mainContainerCheck === null) {
            console.error("Main container is not present!");
            return;
        }
        this._mainContainer = mainContainerCheck;
        // Ui controllers
        this.animation = new Animation_1.Animation(this);
        this.layout = new Layout_1.Layout(this);
        this.forms = new Forms_1.Forms(this);
        this.navigation = new Navigation_1.Navigation(this);
    }
    /**
     * init
     */
    init() {
        console.log("Henri App init");
        this.initControllers();
        this.initComponents();
    }
    /**
     * initControllers
     */
    initControllers() {
        console.log("Henri App controllers init");
        // Controllers
        this.animation.init();
        this.layout.init();
        this.forms.init();
        this.navigation.init();
    }
    /**
     * initComponents
     */
    initComponents() {
        console.log("Henri App components init");
        // Swiper
        const swipers = new Swipers_1.Swipers(this);
        swipers.init();
        // Social Share
        const socialShare = new SocialShareLinks_1.SocialShareLinks();
        socialShare.init();
        const lightboxes = new Lightboxes_1.Lightboxes(this);
        // Tom
        const tomselect = new TomSelects_1.TomSelects();
        tomselect.init();
        this.fakecontent();
        this.showMoreBoxes();
        this.measureBoxes();
        this.productsImagesViewer();
    }
    fakecontent() {
        /*
        Fake dupplicate de boites, à dynamiser avec le back-end.
         */
        const carrouselMob = document.querySelector(".carousel-mobilier");
        if (carrouselMob !== null) {
            const carrouselMobBtn = carrouselMob.querySelector(".js-btn-see-more");
            if (carrouselMobBtn !== null) {
                carrouselMobBtn.addEventListener("click", function (e) {
                    e.preventDefault();
                    let lastbox = carrouselMob.querySelector(".product-box");
                    if (lastbox) {
                        for (var i = 0; i < 4; i++) {
                            let box = carrouselMob.querySelector(".product-related-box");
                            let clone = lastbox.cloneNode(true);
                            box.appendChild(clone);
                        }
                    }
                });
            }
        }
        ;
    }
    /**
     * Boites avec bouton lire plus
     */
    showMoreBoxes() {
        const instance = this;
        const showMoreBtns = document.querySelectorAll(".js-btn-open-more");
        showMoreBtns.forEach(function (btnEl) {
            btnEl.addEventListener("click", function (e) {
                e.preventDefault();
                console.log("click", this.dataset.target);
                let targetStr = this.dataset.target;
                let target = document.querySelector(targetStr);
                this.classList.add("opened");
                if (target != null) {
                    // target.classList.remove("open-more-content-hide");
                    Slide_1.Slide.down(target, 500);
                    // instance.navigation.scrollToElem(target);
                }
                //Less btn
                let closeEl = document.querySelector(`.js-btn-open-less[data-target="${targetStr}"]`);
                closeEl === null || closeEl === void 0 ? void 0 : closeEl.classList.add("opened");
            });
        });
        const showLessBtns = document.querySelectorAll(".js-btn-open-less");
        showLessBtns.forEach(function (btnEl) {
            btnEl.addEventListener("click", function (e) {
                e.preventDefault();
                let targetStr = this.dataset.target;
                let target = document.querySelector(targetStr);
                this.classList.remove("opened");
                if (target != null) {
                    Slide_1.Slide.up(target, 500);
                }
                //openBtn
                let openEl = document.querySelector(`.js-btn-open-more[data-target="${targetStr}"]`);
                openEl === null || openEl === void 0 ? void 0 : openEl.classList.remove("opened");
            });
        });
    }
    /**
     * Switch CM/PO
     */
    measureBoxes() {
        const instance = this;
        const measureBoxesEls = document.querySelectorAll(".js-mesures-box");
        measureBoxesEls.forEach(function (boxEl) {
            let tabBtns = boxEl.querySelectorAll(".tabs-type-mesure a");
            tabBtns.forEach(function (btnEl) {
                btnEl.addEventListener("click", function (e) {
                    e.preventDefault();
                    let activeEl = boxEl.querySelector(".mesure-table.active");
                    activeEl === null || activeEl === void 0 ? void 0 : activeEl.classList.remove("active");
                    let activeBtn = boxEl.querySelector("a.active");
                    activeBtn === null || activeBtn === void 0 ? void 0 : activeBtn.classList.remove("active");
                    this.classList.add("active");
                    let target = document.querySelector(this.dataset.target);
                    target === null || target === void 0 ? void 0 : target.classList.add("active");
                });
            });
        });
    }
    productsImagesViewer() {
        let productsImagesViewerEl = this.mainContainer.querySelector(".produit-details-img-box");
        if (productsImagesViewerEl !== null) {
            let bigImg = productsImagesViewerEl === null || productsImagesViewerEl === void 0 ? void 0 : productsImagesViewerEl.querySelector(".big-img-box img");
            let thumbPics = productsImagesViewerEl.querySelectorAll(".thumb-item");
            Array.from(thumbPics).forEach(thumbEl => {
                thumbEl.addEventListener("click", function (e) {
                    var _a, _b;
                    e.preventDefault();
                    (_a = productsImagesViewerEl.querySelector(".thumb-item.thumb-active")) === null || _a === void 0 ? void 0 : _a.classList.remove("thumb-active");
                    thumbEl.classList.add("thumb-active");
                    bigImg.src = (_b = thumbEl.querySelector("img")) === null || _b === void 0 ? void 0 : _b.src;
                    console.log("click!");
                });
            });
        }
    }
    get mainContainer() {
        return this._mainContainer;
    }
}
exports.App = App;
