"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Swipers = void 0;
const swiper_1 = require("swiper");
class Swipers {
    /**
     * Constructor
     * @param app
     */
    constructor(app) {
        this.app = app;
        // Régler les différents styles ici (dupliquer default)
        this.skins = {
            /**
             * Skin default
             */
            default: {
                direction: "horizontal",
                loop: false,
                breakpoints: {
                    0: {
                    // slidesPerView: 1.3,
                    // spaceBetween: 8,
                    // slidesPerGroup:3
                    },
                    500: {
                    // slidesPerView: 2.2,
                    // spaceBetween: 8,
                    // slidesPerGroup:2
                    },
                    768: {
                    // slidesPerView: 2.4,
                    // spaceBetween: 16,
                    // slidesPerGroup:2
                    },
                    992: {
                    // slidesPerView: 2.3,
                    // spaceBetween: 32,
                    // slidesPerGroup:2
                    },
                    1024: {
                    // slidesPerView: 2.4,
                    // spaceBetween: 32,
                    // slidesPerGroup:2
                    },
                    1280: {
                    // slidesPerView: 3.3,
                    // spaceBetween: 32,
                    // slidesPerGroup:3
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin homehero
             */
            homehero: {
                direction: "horizontal",
                loop: false,
                spaceBetween: 0,
                speed: 1200,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1
                    },
                    1280: {
                        slidesPerView: 1
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin single product
             */
            singleproduct: {
                direction: "horizontal",
                loop: true,
                spaceBetween: 0,
                mousewheel: {
                    forceToAxis: true,
                },
                autoplay: {
                    delay: 3500,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1
                    },
                    1280: {
                        slidesPerView: 1
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin Carrière - environnement
             */
            carriereEnviro: {
                direction: "horizontal",
                loop: true,
                spaceBetween: 0,
                speed: 1200,
                effect: 'fade',
                centeredSlides: true,
                roundLengths: true,
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1
                    },
                    1280: {
                        slidesPerView: 1
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin Carrière - processus
             */
            carriereProcessus: {
                direction: "horizontal",
                loop: false,
                spaceBetween: 10,
                speed: 1200,
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: false,
                breakpoints: {
                    0: {
                        slidesPerView: 1.5
                    },
                    450: {
                        slidesPerView: 2
                    },
                    870: {
                        slidesPerView: 3,
                    },
                    1280: {
                        slidesPerView: 4
                    },
                    1760: {
                        slidesPerView: 5
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin Carrière - galerie MOBILE SEULEMENT
             */
            carriereGalerieMobile: {
                direction: "horizontal",
                loop: false,
                spaceBetween: 20,
                speed: 1200,
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: false,
                breakpoints: {
                    0: {
                        slidesPerView: 1
                    },
                    530: {
                        slidesPerView: 2
                    },
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin Carrière - carousel équipe
             */
            carriereCarouselEquipe: {
                direction: "horizontal",
                loop: false,
                speed: 1200,
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: true
                },
                breakpoints: {
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    450: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    870: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    1280: {
                        slidesPerView: 2,
                        spaceBetween: 45,
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin Product La vraie vie - Démo
             */
            productsDemo: {
                direction: "horizontal",
                loop: true,
                spaceBetween: 0,
                speed: 1200,
                centeredSlides: true,
                roundLengths: true,
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1
                    },
                    1280: {
                        slidesPerView: 1
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin Phase 2 - avec bout rond dans accordeon
             */
            boutRond: {
                direction: "horizontal",
                loop: true,
                spaceBetween: 0,
                speed: 1200,
                effect: 'fade',
                centeredSlides: true,
                roundLengths: true,
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1
                    },
                    1280: {
                        slidesPerView: 1
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin Products Related
             */
            productsRelated: {
                direction: "horizontal",
                loop: true,
                spaceBetween: 0,
                speed: 1200,
                centeredSlides: true,
                roundLengths: true,
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1
                    },
                    1280: {
                        slidesPerView: 1
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            },
            /**
             * Skin temoignages
             */
            temoignages: {
                direction: "horizontal",
                loop: true,
                spaceBetween: 50,
                speed: 1200,
                slidesPerView: 1,
                mousewheel: {
                    forceToAxis: true,
                },
                autoplay: false,
                breakpoints: {},
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                },
                on: {
                    init: function (swiper) {
                        const totalEl = swiper.el.parentNode.querySelector(".total");
                        if (totalEl) {
                            totalEl.textContent = (swiper.slides.length - 2).toString();
                        }
                    },
                    slideChange: function (swiper) {
                        const posEl = swiper.el.parentNode.querySelector(".pos");
                        if (posEl) {
                            posEl.textContent = (swiper.realIndex + 1).toString();
                        }
                    }
                }
            },
            /**
             * Skin commanditaires
             */
            commanditaires: {
                direction: "horizontal",
                loop: true,
                spaceBetween: 50,
                slidesPerView: 3,
                speed: 1200,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: true
                },
                mousewheel: {
                    forceToAxis: true,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 100,
                    },
                    1280: {
                        slidesPerView: 5,
                        spaceBetween: 110,
                    },
                    1480: {
                        slidesPerView: 6,
                        autoplay: false,
                        spaceBetween: 110,
                    }
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true
                }
            }
            /**
             * Fin Skins
             */
        };
        // Liste des swipers
        this.swipers = [];
        console.log("Henri Swipers constructor");
    }
    /**
     * Init
     */
    init() {
        console.log("Henri Swipers init!");
        const containers = this.app.mainContainer.querySelectorAll("div.swiper-container");
        let swiperItem;
        containers.forEach((container, index) => {
            swiperItem = this.initSwiperItem(container, index);
            if (swiperItem instanceof swiper_1.default) {
                this.swipers.push({
                    el: swiperItem,
                    index
                });
            }
        });
        return this;
    }
    initSwiperItem(containerEl, index) {
        console.log("initSwiper", containerEl, index);
        // Skin
        let skinName = containerEl.dataset.skin;
        if (skinName === undefined || this.skins[skinName] === undefined) {
            skinName = "default";
        }
        containerEl.classList.add(`theme-${skinName}`);
        const skin = this.skins[skinName];
        // .swiper element
        const swiperElCheck = containerEl.querySelector("div.swiper");
        if (swiperElCheck === null) {
            console.error("Swiper Main container is not present! div with the class 'swiper' is required");
            return false;
        }
        const swiperEl = swiperElCheck;
        // Next prev nav (added here, to be external of the main div
        const nextEl = containerEl.querySelector(".swiper-button-next");
        const prevEl = containerEl.querySelector(".swiper-button-prev");
        if (nextEl && prevEl) {
            skin.navigation = {
                nextEl,
                prevEl
            };
        }
        // GoGo Swiper!
        const swiper = new swiper_1.default(swiperEl, skin);
        console.log("initSwiperItem success:", swiper);
        return swiper;
    }
}
exports.Swipers = Swipers;
