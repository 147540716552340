"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lightboxes = void 0;
const GLightbox = require("glightbox");
class Lightboxes {
    constructor(app) {
        this.app = app;
        console.log("Lightbox constructor");
        const lightbox = GLightbox({});
    }
}
exports.Lightboxes = Lightboxes;
